import { getApiUrls, API_URL_GOOGLE_OAUTH_PATH } from "@shared/data/api.constants";
import { userStore } from "@store";

const ONE_DAY = 86400e3;

export const onGoogleClick = () => {
  if (userStore.city) {
    const date = new Date(Date.now() + ONE_DAY);
    document.cookie = `city=${JSON.stringify(userStore.city)}; expires=${date.toUTCString()}`;
  }
  window.location.href = `${getApiUrls().API_URL}${API_URL_GOOGLE_OAUTH_PATH}`;
};