import { Post, Comment, Message } from "@graphql/graphql";
import styles from "./reactions.module.css";
import { ReactionComponent } from "../reaction/reaction.comp";
import { ReactionTypes } from "src/assets/reactions";
import { userStore } from "@store";
import { makeReaction } from "../helpers/make-reaction";
import { observer } from "mobx-react-lite";

type Props = {
  item: Post|Comment|Message,
  showReactions?: boolean,
  isComment?: boolean,
  hideOneDigit?: boolean,
  setShowReactions?: (prev: boolean) => void,
  inZoom?: boolean,
}

export const types = ["heart", "like", "dislike", "joy", "sad", "angry" ];
const commentTypes = ["like", "dislike" ];

export const ReactionsComponent = observer(({ item, showReactions, isComment, hideOneDigit, setShowReactions, inZoom }: Props) => {
  const { currentUser } = userStore;
  const username = currentUser?.username ?? '';
  const isPost = item.__typename === 'Post';

  const handleClick = async (type: ReactionTypes) => {
    if (!currentUser || !item?.id) {
      return;
    }

    const currentUserReaction = item?.reactions?.find(reaction => reaction?.username === username);

    if (currentUserReaction?.type === type) {
      await makeReaction(item, undefined);
    } else {
    const postId = item.__typename === 'Comment'
      ? item.postId ?? item.commentId
      : undefined;

    await makeReaction(item, type, postId ?? undefined);
    }

    if(setShowReactions) setShowReactions(false);
  }

  return (
    <div className={`${styles['reaction-feedback']} ${inZoom ? styles['reaction-feedback-inzoom']:''}`}>
      {/* all reactions*/}
      {showReactions && (
        <div className={isPost ? `${styles['reactions-post']} ${inZoom ? styles['reactions-post-inzoom'] : ''}` : styles.reactions}>
          {(isComment ? commentTypes : types).map((type) => {
            const count = item?.reactions?.reduce((sum, reaction) =>
              reaction?.type === type ? sum + 1 : sum, 0
            ) ?? 0;

            const isShow = !!showReactions || count > 0;

            return isShow && (
              <ReactionComponent
                item={item}
                key={type}
                onClick={() => handleClick(type as ReactionTypes)}
                type={type as ReactionTypes}
                choose={!!item?.reactions?.find((reaction) =>
                  reaction?.type === type && reaction.username === username)}
                count={(count === 1 && !!hideOneDigit && !showReactions) ? undefined : count}
              />
            )
          })}
        </div>
      )}
    </div>
  );
});
