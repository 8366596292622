export type countType = 'one' | 'few' | 'many';

export function getCountType(count: number): countType {
  const mod10 = count % 10;
  const mod100 = count % 100;

  if (mod10 === 1 && mod100 !== 11) {
    return 'one';
  } else if (mod10 >= 2 && mod10 <= 4 && (mod100 < 12 || mod100 > 14)) {
    return 'few';
  } else {
    return 'many';
  }
}