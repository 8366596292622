import { Post } from "@graphql/graphql";
import styles from "./pictures-modal-window.module.css";
import { Galery } from "../galery/galery.comp";
import { PostWithCommets } from "../post-with-commets/post-with-commets.comp";
import { Icon } from "@ui-kit";
import { Carousel } from "@ui-kit/components/carousel/carousel.comp";

type Props = {
  post: Post,
  onClose?: () => void,
  pictureState: [number, React.Dispatch<React.SetStateAction<number>>],
  onShareClick:() => void,
}

export const PicturesModalWindow = ({ post, onClose, pictureState, onShareClick }: Props) => {
  return <div className={styles.window}>
    <div className={styles.window__back} onClick={() => onClose && onClose()}/>
    <button className={styles.button} onClick={() => onClose && onClose()}>
      <Icon size='sm' icon='x' color='#FFFFFF' />
    </button>
    <Galery className={styles.media} media={post.media} pictureState={pictureState} />
    <Carousel
      startFrom={pictureState[0]}
      className={styles['carousel']}
      items={post.media ?? []} />
    <div className={styles['post-content']}>
      <PostWithCommets onShareClick={onShareClick} post={post} showPictures={false} showComments={true} />
    </div>
  </div>
}