import { gql } from "@apollo/client";

export const getHidenReactions = gql`query getHidenReactions {
    getHidenReactions{
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
