import { gql } from "@apollo/client";

export const createLinkData = gql`mutation createLinkData($url: String) {
    createLinkData(url: $url){
        id
        url
        title
        text
        image{
            url
            height
            width
            type
        }
        video{
            url
            height
            width
            type
        }
    }
}`;
