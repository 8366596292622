import { gql } from "@apollo/client";

export const getUserFollowers = gql`query getUserFollowers($input: FollowInput) {
    getUserFollowers(input: $input){
        id
        username
        follow
        createdAt
        updatedAt
    }
}`;
