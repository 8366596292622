import { toastsStore, userStore } from "@store";
import styles from "./create-post.module.css";
import { Avatar, Button, UploadingFile } from "@ui-kit/components";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { createNewPost } from "../../helpers/create-new-post"
import { useFormState } from "@shared/hooks";
import { loadPictureToDB } from "src/main-page/helpers/load-pictures-to-db";
import { LinkData, MediaFile } from "@graphql/graphql";
import { v4 as uuid } from "uuid";
import { useLocation } from "react-router-dom";
import { getLinksInText } from "@home";
import { linkService } from "@shared/services/link.service";
import { LinkDataComponent } from "@shared/components/link-data/link-data.comp";
import { filterText } from "src/main-page/helpers/filter-text";
import { useTranslation } from "react-i18next";
import { DropdownUserList } from "@shared/components/dropdown-user-list/dropdown-user-list.comp";
import { foundLastWord } from "src/main-page/helpers/found-last-word";
import { onSelectUser } from "@shared/helpers/on-select-user";
import { ReactionsTextComponent } from "src/main-page/components/post/reactions-text/reactions-text.comp";
import { insertEmoji, saveSelection } from "src/main-page/helpers/insert-emoji";
import { ReactionTypes } from "src/assets/reactions";


const initialState = {
  text: '',
  media: [] as string[],
  linkIds: [] as string[],
};

const buttonParams = {
  size: 'sm' as any, hierarchy: 'tertiary-color' as any,
}

export type FilesToUpload = {
  key: string,
  url: string,
  type: string,
  file: any,
  value: any,
  percent: number,
}

type Props = {
  className?: string;
  showOnMobile?: boolean;
  onSendSucces?: () => void;
}

export const CreatePostComponent = observer(({ className, showOnMobile, onSendSucces }: Props) => {
  const { currentUser } = userStore;

  const { t } = useTranslation('main');

  const textRef = useRef<any>();

  const { formState, setInput, setFormState } = useFormState(initialState);
  const [filesToUpload, setFilesToUpload] = useState<FilesToUpload[]>([]);
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [stringIndex, setStringIndex] = useState<number[]>([0]);
  const [usernamePos, setUsernamePos] = useState<{start?: number, end?: number}>({});
  const [showReactions, setShowReactions] = useState(false);

  className = className ? `${className} ${styles['create-post']}` : styles['create-post'];
  className = showOnMobile ? `${showOnMobile} ${styles['create-post_mobile']}` : className;

  let { search } = useLocation();

  const removeLink = (id: string) => {
    setInput('linkIds', formState.linkIds.filter((link: LinkData|null) => link?.id !== id));
  }

  useEffect(() => {
    if (search.substring(0, 5) === '?add=' && textRef.current) {
      textRef.current.focus();
      const selection = window.getSelection();
      const range = document.createRange();
      try {
        range.setStart(textRef.current, 0);
        range.setEnd(textRef.current, 0);
        selection?.removeAllRanges();
        selection?.addRange(range);
      } catch {}
    }
  }, []);

  const handleInput = () => {
    setInput('text', filterText(textRef.current.innerHTML));
    saveSelection();
  }

  const handleKeyUp = () => {
    saveSelection();
  }

  const handleMouseUp = () => {
    saveSelection();
  };

  let selection = window.getSelection();
  useEffect(() => {
    if (!textRef.current?.innerHTML) {
      return;
    }

    selection = window.getSelection();
    const { lastWord, start, end } = foundLastWord(
      selection?.anchorNode?.textContent ?? '',
      selection?.anchorOffset ?? 0,
    );

    const index = [...textRef.current.childNodes]
      .findIndex((node: any) =>
        selection?.anchorNode?.isEqualNode(node)
        || selection?.anchorNode?.isEqualNode(node?.firstChild)
      );
    if (index >= 0) {
      setStringIndex([index]);
    } else {
      let [indexX, indexY] = [-1, -1];
      [...textRef.current.childNodes]
        ?.forEach((parentNode, i) => {
          if (parentNode.nodeName === 'DIV') {
            [...parentNode?.childNodes]?.forEach((node, j) => {
              if(
                selection?.anchorNode?.isEqualNode(node)
                || selection?.anchorNode?.isEqualNode(node?.firstChild)
              ) {
                indexX = i;
                indexY = j;
              }
            });
          }
        });
      setStringIndex([indexX, indexY]);
    }

    if (lastWord.at(0) === '@') {
      if (lastWord.length >= 3) {
        setUsername(lastWord.substring(1));
      } else {
        setUsername('');
      }
      setUsernamePos({ start, end });
    } else {
      setUsername('');
      setUsernamePos({});
    };

  }, [textRef.current?.textContent])

  useEffect(() => {
    const links = getLinksInText(formState.text);
    Promise.all(
      links.map(async (link) => {
        if (!formState.linkIds.find((linkData: LinkData) => linkData.url.includes(link))) {
          return await linkService.createLinkData(link)
        };

        return null;
      })
    ).then((linkDataList) => {
      setInput('linkIds', [...formState.linkIds, ...linkDataList.filter(Boolean)]);
    })
  }, [formState.text]);

  const sendAction = () => {
    const media = filesToUpload
      .map((file) => mediaFiles.find((media) => media.key === file.key))
      .filter(Boolean);

      createNewPost({...formState, media}, () => {
      setFormState(initialState);
      setFilesToUpload([]);
      setMediaFiles([]);
      if (textRef?.current?.innerHTML) {
        textRef.current.innerHTML = '';
      }
      setUsername('');
      setUsernamePos({});
    }, setLoading)
      .then(() => {
        onSendSucces && onSendSucces();
      });
  }

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      const inputFiles = [...event.target.files].map((f: any) => ({file: f, key: uuid()}));
      setFilesToUpload((prev) => [
        ...prev,
        ...inputFiles.map((file) => ({
          key: file.key,
          url: URL.createObjectURL(file.file),
          type: file.file.type,
          file: file.file,
          value: event.target.value,
          percent: 0,
        }))
      ]);

      inputFiles.forEach((inputFile) => {
        loadPictureToDB(
          inputFile.key,
          inputFile.file,
          event.target.value,
          (percent: number) => {
            setFilesToUpload((prev) => prev.map((file) => {
              if (file.key === inputFile.key) {
                return {
                  ...file,
                  percent,
                }
              }
              return file;
            }))
          }
        ).then((mediaFile) => {
          if (mediaFile?.url) {
            setMediaFiles((prev) => [
              ...prev,
              mediaFile,
            ]);
          } else {
            onClose(inputFile);
          }
        }).catch((err) => {
          toastsStore.addErrorToast(t('toasts.error.loadMedia'));
          console.error(err);
        });
      })
    }
  };

  const onClose = (file: FilesToUpload|{key: string}) => {
    setFilesToUpload((prev) => [
      ...prev.filter((curFile) => curFile.key !== file.key),
    ]);
    setMediaFiles((prev) => [
      ...prev.filter((media: MediaFile) => media.key !== file.key),
    ]);
  }

  if (!currentUser) {
    return <></>
  }

  const id = uuid();

  const handleEmojiSelect = (emoji: ReactionTypes) => {
    insertEmoji({ textRef, emoji, setInput });
  };

  const openEmojiSelector = () => {
    saveSelection();
    setShowReactions(true);
  };

  return (<div className={className}>
    <Avatar url={currentUser.picture?.url ?? undefined} />
    <div className={styles['create-post_right']}>
      <div className={styles['text-container']}>
        <div
          ref={textRef}
          contentEditable='true'
          className={styles['create-post__input']}
          // placeholder={t('createPost.placeholder')}
          onInput={handleInput}
          onKeyUp={handleKeyUp}
          onMouseUp={handleMouseUp}
          />
        {!textRef?.current?.innerHTML && <div className={styles['create-post__input_placeholder']}>{t('createPost.placeholder')}</div>}
      </div>
      {formState.linkIds.length > 0 &&
        <div className={styles['links-data']}>
          {formState.linkIds.filter(Boolean).map((linkData: LinkData) =>
            <LinkDataComponent key={linkData.id} linkData={linkData} onClose={() => removeLink(linkData.id)}/>
          )}
        </div>}
      {filesToUpload.length > 0 &&
        <div className={styles['uploading-files']}>
          {filesToUpload.map((file) => {
            return (
            <UploadingFile
              precent={file.percent < 0 ? 0 : file.percent }
              key={file.key}
              file={file}
              onClose={() => onClose(file)} />
          )})}
        </div>
      }
      <div className={styles['create-post_bottom']}>
        <div className={styles['create-post_buttons']}>
          <form className={styles['form-picture']}>
            <label htmlFor={`upload-post-media-${id}`} className={styles['custom-file-upload']} />
            <input
              id={`upload-post-media-${id}`}
              className={styles.input}
              type='file'
              multiple={true}
              onChange={(event) => handleFileChange(event)}
            />
          </form>
          <Button className={styles['upload-media-button']} type='button' {...buttonParams} iconType='image' />
          <Button {...buttonParams} disabled={true} iconType='map-pin' />
          <Button className={styles['smiles-media-button']} {...buttonParams} disabled={false} iconType='smile' onClick={() => setShowReactions((prev) => !prev)} />

          {showReactions && <ReactionsTextComponent
            setShowReactions={openEmojiSelector}
            showReactions={showReactions}
            onEmojiSelect={handleEmojiSelect} />}
        </div>
        <Button
          className={styles['create-post__send-button']}
          disabled={(formState.text === '' && mediaFiles.length <= 0 && filesToUpload.every((f) => f.percent >= 100))}
          onClick={sendAction} size='md'
          loading={loading}
        >
          {t('createPost.button.publish')}
        </Button>
        <Button
          className={styles['create-post__send-button-mobile']}
          iconType='send'
          size='md'
          disabled={(formState.text === '' && mediaFiles.length <= 0 && filesToUpload.every((f) => f.percent >= 100))}
          onClick={sendAction}
          loading={loading}
        />
        {username !== ''
          && <DropdownUserList
              onSelect={(user) => {
                onSelectUser(user, stringIndex, setUsername, textRef, usernamePos, setUsernamePos);
                setInput('text', textRef.current.innerHTML);
              }}
              top={
                window.innerWidth <= 580
                  ? 110 + 24 * [...(textRef.current?.childNodes ?? [])].length
                  : undefined
              }
              className={styles['create-post__dropdown-user-list']}
              setText={setUsername}
              text={username}
            />}
      </div>
    </div>
  </div>)
});