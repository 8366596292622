import styles from "./loader.module.css";

export type LoaderSize = "xs" | "sm" | "md" | "lg" | "xl";

export type LoaderType = "quart" | "line" | "dots" | "download";

type Props = {
  loaderType?: LoaderType;
  loaderSize?: LoaderSize;
  supportingText?: string;
  children?: any;
  className?: string;
};

export const Loader = ({
  loaderType,
  loaderSize,
  supportingText,
  className,
  children
}: Props) => {
  loaderType = loaderType ?? 'quart';
  loaderSize = loaderSize ?? 'sm';

  let loaderCssClass  = styles[`loader_${loaderType}`];

  let sizeCssClass = styles[`size-${loaderSize}`];

  let sizeDotsCssClass =  styles[`size-dots_${loaderSize}`];

  return (
    <div className={`${styles['loader-container']}
    ${loaderSize==='lg' ? styles['loader-container_lg-xl']: ''}
    ${loaderSize==='xl' ? styles['loader-container_lg-xl']: ''}
    `}>
      {loaderType === 'download' ?
      (children) : (
        <>
      <span className={`${loaderCssClass}
      ${loaderType==='dots' ? sizeDotsCssClass : sizeCssClass}`}>
    </span>
    </>
    )}
      {supportingText && <span className={className}>{supportingText}</span>}
    </div>
  );
};