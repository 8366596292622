import Icons, { ReactionTypes } from "src/assets/reactions";

type Props = {
  type: ReactionTypes;
};

export const Emoji = ({ type }: Props) => {
  const imgSrc = Icons[type];
  return <img src={imgSrc} alt={type} style={{ width: '16px', height: '16px' }} />;
};
