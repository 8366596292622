import { gql } from "@apollo/client";

export const unblockUser = gql`mutation unblockUser($username: String) {
    unblockUser(username: $username){
        username
        blockedUsername
        isBlocked
        blockData{
            isBlocked
            createdAt
            updatedAt
        }
        createdAt
        updatedAt
    }
}`;
