import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "@graphql/graphql";
import styles from "./trend-item.module.css";
import { Avatar } from "@ui-kit/components/avatar/avatar.comp";
import { getShortNumber } from "@shared/helpers/get-short-number";
import { getCorrectPostDate } from "src/main-page/components/post/helpers/get-correct-post-date";
import { Icon } from "@ui-kit";
import { ContentData } from "../content-data/content-data.comp";

type Props = {
  item: Post,
}

export const TrendItem = ({ item }: Props) => {
  const [post, setPost] = useState(item);
  const navigate = useNavigate();

  const openLinkPost = () => {
    navigate(`/post/${post?.id}`, { state: { post } });
  };

  const openUserWall = () => navigate(`/wall/${post?.username}`);

  return <div className={styles.item}>
    <div className={styles.item__title} onClick={openLinkPost} ><ContentData isTrends={true} text={post?.text ?? ''} /></div>
    <div className={styles.item__bottom}>
      <Avatar
        url={post?.userPicture?.picture?.url ?? undefined}
        size='xs'
        onClick={openUserWall}
        statusIcon={post?.userPicture?.isOnline ? 'online' : undefined}
      />
      <p className={styles.item__fullname}>{post?.userPicture?.firstname} {post?.userPicture?.lastname}</p>
      <p className={styles.item__time}>{post ? getCorrectPostDate(post).relativeTime : ''}</p>
      <div className={styles.item__views}>
        <Icon icon='eye' color='#98A2B3' size='xxs' />
        {getShortNumber(post?.view ?? 0)}
      </div>
    </div>
  </div>
}