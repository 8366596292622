import styles from "./bottom-menu.module.css";
import { messagesStore, userStore } from "@store";
import { Badge } from "../badge/badge.comp";
import { NavButton } from "../nav-button/nav-button.comp";
import { BottomMenuItem } from "./data/bottom-menu-item";
import { useEffect, useState } from "react";
import { getBottomMenuItems } from "src/main-page/data/bottom-menu-items";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { MAIN_MENU_KEYS } from "src/main-page/components/main-menu/useMainMenu";
import { notificationsStore } from "src/store/notifications.store";

type Props = {
  className?: string,
  onCreateClick?: () => void
}

export const BottomMenu = observer(({ className, onCreateClick }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const fullClassName = className ? `${className} ${styles['bottom-menu']}` : styles['bottom-menu'];

  const { currentUser } = userStore;

  const [bottomMenuItems, setBottomMenuItems] = useState<BottomMenuItem[]>(getBottomMenuItems(false));

  const location = useLocation();

  const [bottomMenuItemKey, setBottomMenuItemKey] = useState<string>(getBottomMenuItems(false)[0].key);

  useEffect(() => {
    setBottomMenuItems(
      getBottomMenuItems(!!currentUser)
        .map((item) =>
          item.key === MAIN_MENU_KEYS.MESSAGES
            ? { ...item, notifications: messagesStore.messagesCout }
            : item.key === MAIN_MENU_KEYS.NOTIFICATION
              ? { ...item, notifications: notificationsStore.notificationsLength }
              : item
        ),
    );
    setBottomMenuItemKey(getBottomMenuItems(!!currentUser)[0].key);
  }, [currentUser, messagesStore.messagesCout, notificationsStore.notificationsLength]);

  useEffect(() => {
    switch(pathname.split('/')?.[1]) {
      case 'messages': setBottomMenuItemKey(MAIN_MENU_KEYS.MESSAGES);
        break;
      case 'search': setBottomMenuItemKey(MAIN_MENU_KEYS.SEARCH);
        break;
      case 'notifications': setBottomMenuItemKey(MAIN_MENU_KEYS.NOTIFICATION);
        break;
      default: setBottomMenuItemKey(MAIN_MENU_KEYS.WALL);
    }
  }, [pathname]);

  return <div className={styles['button']}>
    {!!currentUser && !!onCreateClick
      && location.pathname === '/'
      && <Badge
        className={styles['add-post']}
        color='primary'
        iconType='plus'
        icon='icon-left'
        size='lg'
        onClick={() => onCreateClick ? onCreateClick() : {}}
      >
        Опубликовать
      </Badge>}
    <div className={fullClassName}>
      {bottomMenuItems.map((item: BottomMenuItem) => <NavButton
        disabled={item.disabled}
        key={item.key}
        className={styles['nav-button']}
        icon={item.icon}
        showDot={!!item.notifications}
        active={item.key === bottomMenuItemKey}
        label={item.key}
        onClick={() => {
          setBottomMenuItemKey(item.key);
          switch(item.key) {
            case MAIN_MENU_KEYS.WALL: navigate('/'); break;
            case MAIN_MENU_KEYS.SEARCH: navigate('/search'); break;
            case MAIN_MENU_KEYS.MESSAGES: navigate('/messages'); break;
            case MAIN_MENU_KEYS.NOTIFICATION: navigate('/notifications'); break;
          }
        }}/>)}
    </div>
  </div>
});
