import { gql } from "@apollo/client";

export const deleteRole = gql`mutation deleteRole($name: String!) {
    deleteRole(name: $name){
        id
        name
        title
        type
        color
        textColor
        isVisible
    }
}`;
