import { Post, Comment, Message } from "@graphql/graphql";
import styles from "./reactions-summary.module.css";
import { ReactionComponent } from "../reaction/reaction.comp";
import { ReactionTypes } from "src/assets/reactions";
import { userStore, modalCustomDialogsStore } from "@store";
import { makeReaction } from "../helpers/make-reaction";
import { observer } from "mobx-react-lite";
import { ShowFeedbackComponent } from "../show-feedback/show-feedback.comp";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";
import { ReactionsModalComponent } from "../reactions-modal-comp/reactions-modal.comp";

type Props = {
  item: Post|Comment|Message,
  showReactions?: boolean,
  isComment?: boolean,
  hideOneDigit?: boolean,
  setShowReactions?: (prev: boolean) => void,
  inZoom?: boolean,
}

export const types = ["heart", "like", "dislike", "joy", "sad", "angry" ];
const commentTypes = ["like", "dislike" ];

export const ReactionsSummaryComponent = observer(({ item, showReactions, isComment, hideOneDigit, setShowReactions, inZoom }: Props) => {
  const { currentUser } = userStore;
  const username = currentUser?.username ?? '';
  const isPost = item.__typename === 'Post';

  const totalReactions = item?.reactions?.length ?? 0;

  const popularReactions = (isComment ? commentTypes : types)
  .map((type) => ({
    type,
    count: item?.reactions?.reduce((sum, reaction) =>
      reaction?.type === type ? sum + 1 : sum, 0
    ) ?? 0
  }))
  .sort((a, b) => b.count - a.count)
  .slice(0, 2);

  const handleClick = async (type: ReactionTypes) => {
    if (!currentUser || !item?.id) {
      return;
    }

    const currentUserReaction = item?.reactions?.find(reaction => reaction?.username === username);

    if (currentUserReaction?.type === type) {
      await makeReaction(item, undefined);
    } else {
    const postId = item.__typename === 'Comment'
      ? item.postId ?? item.commentId
      : undefined;

    await makeReaction(item, type, postId ?? undefined);
    }

    if(setShowReactions) setShowReactions(false);
  }

  const openModal = () => {
    modalCustomDialogsStore.showDialog(
      <ReactionsModalComponent
        closeModal={() => modalCustomDialogsStore.hideDialog()}
        username={username}
        item={item}
        onReactionClick={handleClick}
      />,
      { title: 'Реакции' }
    );
  };

  return (
    <div className={`${styles['reaction-feedback']} ${inZoom ? styles['reaction-feedback-inzoom']:''}`}>
      {/* summary reactions */}
      {!showReactions && (
        <div className={styles['reactions-summary']}>
            {popularReactions.map(({ type, count }) => count > 0 && (
              <ReactionComponent
                isSummary={true}
                key={type}
                item={item}
                type={type as ReactionTypes}
                choose={!!item?.reactions?.find(reaction =>
                reaction?.type === type && reaction.username === username
                )}
                count={count}
                onClick={() => handleClick(type as ReactionTypes)}
            />
          ))}

          {totalReactions > 0 && (
            <div className={styles['count-contain']}>
              <span className={styles['reaction-count']}>
                {totalReactions}
              </span>
              <IconButton size='xs' iconType='chevron-down' onClick={openModal}/>
            </div>
          )}
        </div>
      )}
      <div className={styles['text-feedback-container']}>
        {isPost && <ShowFeedbackComponent post={item} inZoom={inZoom}/>}
      </div>
    </div>
  );
});
