import { API_URL_UPLOAD_IMAGE_PATH, API_URL_UPLOAD_VIDEO_PATH } from "@shared/data/api.constants";
import axiosUploader from "./axios-uploader";
import { MediaFile } from "@graphql/graphql";
import { toastsStore } from "@store";
import { t } from "i18next";
import { determineFileType } from "./determine-file-type";
import { getVideoDuration } from "./video-duration";
import { compressImage } from "./compress-image";

const uploadFileAxios = (
  formData: FormData,
  uploadType: 'image'|'video',
  onUploadProgress?: (percentCompleted: number) => void,
  ) =>
  axiosUploader.post<MediaFile>(
    uploadType === 'image' ? API_URL_UPLOAD_IMAGE_PATH : API_URL_UPLOAD_VIDEO_PATH,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cross-Origin-Opener-Policy': 'same-origin',
        'Cross-Origin-Embedder-Policy': 'require-corp'
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress && progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 90) / progressEvent.total);
          onUploadProgress(percentCompleted);
        }
      },
    }
  )
  .then((res) => res.data)
  .catch(() => null)

const processImage = async (file: File, fileName: string, formData: FormData): Promise<void> => {
  if (file.size >= 3000000) {
    const compressedFile = await compressImage(file);
    formData.append('file', compressedFile);
  } else {
    formData.append('file', file);
  }
    formData.append('fileName', fileName);
  };

const processVideo = async (file: File, fileName: string, formData: FormData): Promise<void> => {
  const duration = await getVideoDuration(file);

  try {
    let processedFile = file;

    formData.append('file', processedFile);
    formData.append('fileName', fileName);

  } catch (error) {
    console.error('Ошибка при обработке видео:', error);
    throw error;
  }
};

export const uploadFile = async (
  fileName: string,
  file: File,
  onUploadProgress?: (percentCompleted: number) => void,
): Promise<MediaFile | null> => {
  try {
    const fileType = determineFileType(file);

    if (fileType === 'unknown') {
      toastsStore.addErrorToast(t('toasts.error.loadType'));
      return null;
    }

    let formData = new FormData();

    if (fileType === 'image') {
      await processImage(file, fileName, formData);
    } else if (fileType === 'video') {
      await processVideo(file, fileName, formData);
    }

    if (fileType !== 'image' && fileType !== 'video') {
      toastsStore.addErrorToast(t('toasts.error.loadMedia'));
      return null;
    }

    const mediaFile = await uploadFileAxios(formData, fileType, onUploadProgress);

    if (!mediaFile) {
      toastsStore.addErrorToast(t('toasts.error.loadMedia'));
    }

    if (onUploadProgress) {
      onUploadProgress(100);
    }

    return mediaFile ?? null;
  } catch (error) {
    console.error("Ошибка при загрузке файла:", error);
    toastsStore.addErrorToast(t('toasts.error.loadMedia'));
    return null;
  }
};
