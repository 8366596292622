import { modalMessageStore, modalCustomDialogsStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { ModalMessage } from "./modal-message.comp";
import { ModalCustomDialog } from "./modal-custom.comp";
import { useTranslation } from "react-i18next";

export const ModalWindows = observer(() => {
  const { t } = useTranslation('common');

  return (
    <>
      <ModalMessage
        show={modalMessageStore.isShowingMessage}
        onHide={modalMessageStore.hideMessage}
        title={modalMessageStore.title}
        message={modalMessageStore.message}
        />
      <ModalCustomDialog
        show={modalCustomDialogsStore.isShowingDialog}
        onHide={(value) => {
          if (value) {
            modalCustomDialogsStore.confirm()
            ?.catch((err: any) => toastsStore.addErrorToast(t('toasts.error.makeAction')));
          } else {
            modalCustomDialogsStore.cancel();
          }
          modalCustomDialogsStore.hideDialog();
        }}
        typeIcon={modalCustomDialogsStore.typeIcon}
        title={modalCustomDialogsStore.title}
        message={modalCustomDialogsStore.message}
        firstButtonText={modalCustomDialogsStore.firstButtonText}
        secondButtonText={modalCustomDialogsStore.secondButtonText}
        firstButtonDestructive={modalCustomDialogsStore.firstButtonDestructive}
        secondButtonDestructive={modalCustomDialogsStore.secondButtonDestructive}
      >
        {modalCustomDialogsStore.children}
      </ModalCustomDialog>
    </>
  )
});
