
export function determineFileType(file: File): string {
  const mimeType = file.type.toLowerCase();
  const imageMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml', 'image/heic'];
  const videoMimeTypes = ['video/mp4', 'video/avi', 'video/x-matroska', 'video/quicktime', 'video/x-ms-wmv', 'video/x-flv', 'video/3gpp'];

  if (imageMimeTypes.includes(mimeType)) {
    return 'image';
  }

  if (videoMimeTypes.includes(mimeType)) {
    return 'video';
  }

  return 'unknown';
}