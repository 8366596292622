import { gql } from "@apollo/client";

export const hideReaction = gql`mutation hideReaction($id: ID) {
    hideReaction(id: $id){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
