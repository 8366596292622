import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";

export enum ADMIN_MENU_KEYS {
  DASHBOARD = 'dashboard',
  SEARCH = 'search',
  USERS = 'users',
  REPORT = 'reports',
  HOME = '/'
}

export const initialAdminMenu: MenuItem[] = [
  {
    key: ADMIN_MENU_KEYS.HOME,
    type: 'menu-item',
    title: 'Главная',
    showIcon: true,
    grayIcon: 'home',
    // disabled: true
  },
  {
    key: ADMIN_MENU_KEYS.DASHBOARD,
    type: 'menu-item',
    title: 'Дашборд',
    showIcon: true,
    grayIcon: 'grid',
    // disabled: true
  },
  {
    key: ADMIN_MENU_KEYS.SEARCH,
    type: 'menu-item',
    title: 'Поиск',
    showIcon: true,
    grayIcon: 'search',
    disabled: true
  },
  {
    key: ADMIN_MENU_KEYS.USERS,
    type: 'menu-item',
    title: 'Пользователи',
    showIcon: true,
    grayIcon: 'users',
    // disabled: true
  },
  {
    key: ADMIN_MENU_KEYS.REPORT,
    type: 'menu-item',
    title: 'Жалобы',
    showIcon: true,
    grayIcon: 'alert-triangle',
    // disabled: true
  }
];

const showKeys: ADMIN_MENU_KEYS[] = [
  ADMIN_MENU_KEYS.DASHBOARD,
  ADMIN_MENU_KEYS.SEARCH,
  ADMIN_MENU_KEYS.USERS,
  ADMIN_MENU_KEYS.REPORT,
  ADMIN_MENU_KEYS.HOME
];

export const getInitialAdminMenu = (hasUser: boolean) => {
  if(!hasUser) {
    return initialAdminMenu
      .filter((menuItem) => showKeys.includes(menuItem.key as ADMIN_MENU_KEYS))
  }

  return initialAdminMenu;
}