import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "@store";
import { Post, Comment, Message, UserPicture } from "@graphql/graphql";
import styles from "./reactions-modal.module.css";
import { ReactionComponent } from "../reaction/reaction.comp";
import { ReactionTypes } from "src/assets/reactions";
import { FollowComponent } from "src/follow-page/components/follow.comp";

const allReactionTypes: ReactionTypes[] = ["heart", "like", "dislike", "joy", "sad", "angry"] as const;

type Props = {
  item: Post | Comment | Message;
  username?: string;
  onReactionClick: (type: ReactionTypes) => void;
  closeModal: () => void,
}

export const ReactionsModalComponent = ({ item, username, onReactionClick, closeModal }: Props) => {
  const [reactions, setReactions] = useState(item.reactions ?? []);
  const [usersData, setUsersData] = useState<Record<string, UserPicture | null>>({});
  const navigate = useNavigate();

  useEffect(() => {
    setReactions(item.reactions ?? []);
  }, [item.reactions]);

  useEffect(() => {
    const fetchUserData = async () => {
      const userPromises = reactions.map(async (reaction) => {
        if (reaction?.username) {
          const userData = await userStore.getUserData(reaction.username);
          return { username: reaction.username, userData: userData ?? null };
        }
        return { username: null, userData: null };
      });

      const fetchedUsersData = await Promise.all(userPromises);

      const newUsersData = fetchedUsersData.reduce((acc, { username, userData }) => {
        if (username && typeof username === 'string') {
          acc[username] = userData;
        }
        return acc;
      }, {} as Record<string, UserPicture | null>);

      setUsersData(newUsersData);
    };

    fetchUserData();
  }, [reactions]);

  if (!username) {
    return;
  }

  const reactionCounts = reactions.reduce((acc, reaction) => {
    if (reaction && reaction.type) {
      const reactionType = reaction.type as ReactionTypes;
      acc[reactionType] = (acc[reactionType] || 0) + 1;
    }
    return acc;
  }, {} as Record<ReactionTypes, number>);


  return (
    <div className={styles['reactions-modal']}>
      <div className={styles['reactions-summary']}>
        {allReactionTypes.map((type) => {
          return (
            <ReactionComponent
              key={type}
              type={type}
              count={reactionCounts[type as ReactionTypes] ?? 0}
              item={item}
              isModal={true}
              choose={!!item?.reactions?.find((reaction) =>
                reaction?.type === type && reaction.username === username
              )}
              onClick={()=> {
                onReactionClick(type as ReactionTypes)
               closeModal()}}
            />
          );
        })}
      </div>

      {reactions.length > 0 ? (
        reactions.map((reaction) => {
        const userData = reaction?.username ? usersData[reaction.username] : null;

        if (reaction?.username === username) {
          return null;
        }

        return (
          userData && reaction &&(
          <FollowComponent
            className={styles['reactions-modal-follow']}
            onClick={() => {
              navigate(`/wall/${reaction.username}`)
              closeModal()}}
            user={userData as UserPicture}
            key={reaction.username}
            reaction={reaction.type as ReactionTypes}
          />
          )
        );
        })
      ) : (
        <div>Нет реакций</div>
      )}
    </div>
  );
};