export enum Mode {
  PROD='production',
  DEV='development',
  LOCAL='local'
}

const getAppMode = () => {
  const DEV_MODE = process.env.REACT_APP_MODE_ENV?.toLowerCase();
  switch(DEV_MODE) {
    case Mode.PROD: return Mode.PROD;
    case Mode.DEV: return Mode.DEV;
    default: return Mode.LOCAL;
  }
}

export const getApiUrls = () => {
  const mode = getAppMode();

  switch(mode) {
    case(Mode.PROD): return {
      API_URL: 'https://ecitty.com',
      API_URL_WS: 'wss://ecitty.com/graphql',
      HOST_URL: 'https://ecitty.com',
      API_URL_GQL: 'https://ecitty.com/graphql',
    }
    case(Mode.DEV): return {
      API_URL: 'https://ecitty.online',
      API_URL_WS: 'wss://ecitty.online/graphql',
      HOST_URL: 'https://ecitty.online',
      API_URL_GQL: 'https://ecitty.online/graphql',
    }
    default: return {
      API_URL: 'http://localhost:3000',
      API_URL_WS: 'ws://localhost:4000/graphql',
      HOST_URL: 'http://localhost:3000',
      API_URL_GQL: 'http://localhost:3000/graphql',
    }
  };
}

export const API_URL_UPLOAD_VIDEO_PATH = `/upload/video`;
export const API_URL_UPLOAD_IMAGE_PATH = `/upload/image`;
export const API_URL_GOOGLE_OAUTH_PATH = `/oauth/google`;
