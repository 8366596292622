import { useState, useEffect } from "react";
import { citiesStore } from "@store";
import { Post } from "@graphql/graphql";
import { dataRecommends } from "../../../search-page/helpers/recommends";
import styles from "./trends.module.css";
import { RightMenuItem } from "@shared/components/right-menu-item/right-menu-item.comp";
import { TrendItem } from "@shared/components/trend-item/trend-item.comp";
import { useTranslation } from "react-i18next";
import { Loader } from "@ui-kit";
import { observer } from "mobx-react-lite";


export const Trends = observer(() => {
  const [posts, setPosts] = useState<Post[]>([]);
  const { currentCity } = citiesStore;
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation('main');

  useEffect(() => {
    const loadTrends = async () => {
      if (currentCity?.place_id) {
        setLoading(true);
        try {
          const result = await dataRecommends({ place_id: currentCity?.place_id });
          setPosts(result?.postItems as Post[] ?? []);
        } catch (error) {
          console.error('Error loading trends:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadTrends();
  }, [currentCity]);


  return (
    <RightMenuItem>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('trends.header')}</h2>
      </div>
      {loading
      ? <Loader loaderType={'quart'} className={styles['loader-text']} supportingText = {t('trends.loader-text')}/>
      : posts.slice(0, 5).map((post) => (
          <TrendItem key={post.id} item={post} />
      ))}
    </RightMenuItem>
  )
})