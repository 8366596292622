import { gql } from "@apollo/client";

export const listRoles = gql`query listRoles {
    listRoles{
        id
        name
        title
        type
        color
        textColor
        isVisible
    }
}`;
