import { gql } from "@apollo/client";

export const getCommentReactions = gql`query getCommentReactions($commentId: ID) {
    getCommentReactions(commentId: $commentId){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
