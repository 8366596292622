import styles from "./header.module.css";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";
import { userStore } from "@store";
import { Avatar, Button, Text } from "@ui-kit";
import logomark from "@assets/images/Logomark.svg"
import { observer } from "mobx-react-lite";
import { SearchSelector } from "src/search-page/components/search-selector/search-selector";
import { CitySelector } from "src/main-page/components/city-selector/city-selector.comp";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string,
  isMainPage?: boolean,
  hideBackArrow?: boolean,
  title?: string,
  userMenuState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  children?: any,
  hideAvatar?: boolean,
  onBackClick?: () => void,
  subTitle?: string,
  isSearchPage?: boolean,
}

export const Header = observer(({
  className, isMainPage, title, userMenuState, children, hideAvatar, onBackClick, hideBackArrow, subTitle,
  isSearchPage }: Props) => {
  className = className ? `${styles.header} ${className}` : styles.header;
  const { currentUser } = userStore;

  const navigate = useNavigate();
  const { t } = useTranslation('common');

  onBackClick = onBackClick ? onBackClick : () => {
    if (!!window.history.state.idx) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (<>
    <div className={className}>
      {!isMainPage && !hideBackArrow && <IconButton iconType='arrow-left' size='xl' onClick={onBackClick} />}
      {isMainPage && <img className={styles['logomark']} src={logomark} alt='Ecitty' onClick={() => document.body.scrollTo({top: 0, behavior: 'smooth'})} />}
      <Text className={styles.text}>{title ?? t('header.wall')}</Text>
      {!!subTitle && <div className={styles['subtitle']}>{subTitle}</div>}
      {!isMainPage && <Text className={styles['text-mobile']}>{title ?? t('header.wall')}</Text>}
      {isSearchPage && children}
      {isMainPage && <CitySelector className={styles.city} />}
      {isSearchPage && <SearchSelector className={styles.city}/>}
      {!!currentUser && !hideAvatar && <Avatar
        className={isMainPage ? styles['avatar'] : `${styles['avatar']} ${styles['avatar_no-main']}`}
        size='md'
        alt={currentUser?.username ?? 'user'}
        url={currentUser?.picture?.url ?? ''}
        onClick={() => {userMenuState && userMenuState[1]((prev) => !prev)}} />}
      {!currentUser && isMainPage &&
        <Button
          className={styles['login-button']}
          iconType='log-in'
          size='md'
          onClick={() => navigate('login')}
          header_login-button
          label='login'
        />
      }
      {!isSearchPage && children}
    </div>
    <div className={styles.header_back} />
  </>)
});