import { urlRegExp } from "@home";
import { userStore } from "@store";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import styles from "./content-data.module.css";
import { trimUsername } from '@shared/helpers/trim-username';
import { renderTextEmoji } from "@shared/helpers/render-text-emoji";

type Props = {
  text: string,
  usernames?: string[],
  isTrends?: boolean
}

export const ContentData = ({ text, usernames, isTrends }: Props) => {
  const [components, setComponents] = useState<any[]>([]);

  useEffect(() => {
    const lines = text.split('\n');

    Promise.all(lines.map(async (line, i) => {
      const words = await Promise.all(line.split(/(\s+)/).map(async (word) => {
        if (word.match(urlRegExp)) {
          return <Fragment key={`${uuid()}-${i}`}><a href={word}>{word}</a> </Fragment>
        }

        if (word[0] === '@') {
          const [realUsername, symbols] = trimUsername(word);
          if (usernames === undefined) {
            const user = await userStore.getUserData(realUsername.substring(1))
            if (user?.username === realUsername.substring(1)) {
              return <Fragment key={`${uuid()}-${i}`}><Link className={styles.link} to={`/wall/${realUsername.substring(1)}`}>{realUsername}</Link>{symbols} </Fragment>
            }
          } else if(usernames.includes(realUsername.substring(1))) {
            return <Fragment key={`${uuid()}-${i}`}><Link className={styles.link} to={`/wall/${realUsername.substring(1)}`}>{realUsername}</Link>{symbols} </Fragment>
          }
        }

        if (word[0] === '#') {
          return <Fragment key={`${uuid()}-${i}`}>
            <Link className={styles.link} to={`/search?q=%23${word.substring(1)}`}>{word}</Link> </Fragment>
        }

        const emoji = renderTextEmoji(word);
        if (emoji.some((part) => typeof part !== 'string')) {
          return <Fragment key={`${uuid()}-${i}`}>{emoji}</Fragment>;
        }

        return <Fragment key={`${uuid()}-${i}`}>{word} </Fragment>
      }));
      return <p key={`${uuid()}-${i}`} className={isTrends ? `${styles.line} ${styles['line-trends']}` : styles.line}>{words}</p>
    }))
      .then(setComponents);
  }, []);

  return <>{components}</>
}