import { Post } from "@graphql/graphql";
import styles from "./show-feedback.module.css";
import { useTranslation } from "react-i18next";
import { getCountType } from "../helpers/get-count-type";


type Props = {
  post: Post,
  inZoom?: boolean,
}

export const ShowFeedbackComponent = ({ post, inZoom }: Props) => {

  const { t } = useTranslation('main');
  let inZoomClassName = styles['feedback-zoom'];

  return <div className={inZoom ? `${styles['feedback']} ${inZoomClassName}`: `${styles['feedback']}`}>
    <span className={styles['feedback-text']}>{post.commentsLength ? t(`feedback.comments.count.${getCountType(post.commentsLength)}`, { count: post.commentsLength }) :  t('feedback.comments.count.many', { count: 0 })}</span>
    <span className={styles['feedback-text']}>{post.view ? t(`feedback.views.count.${getCountType(post.view)}`, { count: post.view }) : ''}</span>
  </div>
};