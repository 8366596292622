import { gql } from "@apollo/client";

export const getLinkData = gql`query getLinkData($id: ID) {
    getLinkData(id: $id){
        id
        url
        title
        text
        image{
            url
            height
            width
            type
        }
        video{
            url
            height
            width
            type
        }
    }
}`;
