import { Comment, CommentWithPost, FullReaction, Post, Reaction } from "@graphql/graphql";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';

dayjs.locale('ru');
dayjs.extend(relativeTime);

export const getCorrectPostDate = (item: Post|Comment|Reaction|FullReaction|CommentWithPost|{ createdAt: number }) => {
  const dateMs = item.createdAt ?? 0;
  const date = dayjs(dateMs);

  const relativeTime = date.fromNow();

  const formattedDate = date.format("DD.MM.YYYY [в] HH:mm");

  return {relativeTime, formattedDate};
}