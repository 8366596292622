import styles from "../button.module.css";
import socialIcons from "../../../../assets/social-icons/index";
import { useTranslation } from "react-i18next";

type SmsButtonProps = {
  onClick?: () => void;
  supportingText: boolean;
  disabled?: boolean;
};

const SmsButton = ({
  onClick,
  disabled,
  supportingText,
}: SmsButtonProps) => {

  if (!onClick) {
    onClick = () => {};
  }

  const { t } = useTranslation('common');

  return (
    <button
      className={`${styles['button']} ${styles['button_size-xl']} ${styles['button_quaternary-color']}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={socialIcons['social-sms-blue']} alt='sms Icon' />
      {supportingText && <span>{t('socialButton.sms')}</span>}
    </button>
  );
};


export default SmsButton;