import Icons, { ReactionTypes } from "src/assets/reactions";

let savedSelection: { range: Range | null, node: Node | null } = { range: null, node: null };

type Props = {
  textRef: any;
  emoji: ReactionTypes;
  setInput: (key: 'text', value: string) => void;
};

export const saveSelection = () => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    savedSelection.range = selection.getRangeAt(0).cloneRange();
    savedSelection.node = selection.anchorNode;
  }
};

export const restoreSelection = () => {
  const selection = window.getSelection();
  if (savedSelection.range && savedSelection.node && selection) {
    selection.removeAllRanges();
    selection.addRange(savedSelection.range);
  }
};

export const insertEmoji = ({ textRef, emoji, setInput }: Props) => {
  if (textRef.current) {
    textRef.current.focus();

    restoreSelection();

    const imgSrc = Icons[emoji];
    const imgElement = document.createElement("img");
    imgElement.src = imgSrc;
    imgElement.alt = emoji;
    imgElement.style.width = "16px";
    imgElement.style.height = "16px";

    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (range) {
      range.insertNode(imgElement);

      range.setStartAfter(imgElement);
      range.setEndAfter(imgElement);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }

    setInput('text', textRef.current.innerHTML);

    savedSelection = { range: null, node: null };
  }
};