import Icons, { ReactionTypes } from "src/assets/reactions";
import { Post, Comment, Message } from "@graphql/graphql";
import styles from "./reaction.module.css";

type Props = {
  count?: number,
  choose?: boolean,
  type: ReactionTypes,
  onClick: () => void,
  item?: Post|Comment|Message,
  isSummary?: boolean,
  isModal?: boolean,
}

export const ReactionComponent = ({ count, choose, type, onClick, item, isSummary, isModal }: Props) => {
  const isPost = item?.__typename === 'Post';
  isModal = isModal ?? false;
  isSummary = isSummary ?? false;

  const url = `url(${Icons[type]})`;

  let iconClassName = "";
  if (isModal) {
    iconClassName = styles["icon-small"];
  } else if (isPost && isSummary) {
    iconClassName = styles["icon-summary"];
  } else if (isPost && !isSummary && !isModal) {
    iconClassName = styles["icon-large"];
  } else {
    iconClassName = styles["icon-small"];
  }

  let reactionClassName = "";
  if (isModal) {
    reactionClassName = `${styles["reaction-small"]} ${styles["modal"]}`;
  } else if (isPost && isSummary) {
    reactionClassName = styles["reaction-summary"];
  } else if (isPost) {
    reactionClassName = styles["reaction-large"];
  } else {
    reactionClassName = styles["reaction-small"];
  }

  let chooseClassName = "";
  if (isModal) {
    chooseClassName = styles["choose-small"];
  } else if (isPost && isSummary) {
    chooseClassName = styles["choose-summary"];
  } else if (isPost) {
    chooseClassName = styles["choose-large"];
  } else {
    chooseClassName = styles["choose-small"];
  }

  const className = choose ? `${styles.reaction} ${styles.icon} ${styles.choose} ${chooseClassName} ${reactionClassName}` : `${reactionClassName}`;

  return <div className={className} onClick={() => onClick()}>
    <div className={`${styles.icon} ${iconClassName}`} style={{backgroundImage: url}} />

    {
    !!count &&
      (!isPost || isModal) && <p className={styles.count}>{count}</p>}
    </div>
}