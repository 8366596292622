import { emojiUnicodes } from "@shared/data";

export const convertHTMLTextToCorrectText = (text?: string | null): string => {
  if (!text) return '';

  return text
    .replace(/<img[^>]+alt="(\w+)"[^>]*>/gi, (match, alt) => {
      return emojiUnicodes[alt] || '';
    })
    .replaceAll('&nbsp;', ' ')
    .replaceAll('<div><br></div>', '')
    .replaceAll('<div>', '\n')
    .replaceAll('</div>', '')
    .replaceAll(/(<([^>]+)>)/gi, '') ?? '';
};
