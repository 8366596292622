import { Button, Icon } from "@ui-kit";
import styles from "./modal.module.css";
import React, { useEffect } from 'react';
import { modalCustomDialogsStore } from "@store";

export type ModalIconOption = | "success" | "warning"| "error" | "info" | "unlock" | "exit" | "enter" | "delete" | null;

interface Props {
  typeIcon?: ModalIconOption,
  show: boolean,
  onHide: (isConfirmed: boolean) => void,
  title?: string,
  message?: string,
  children?: any,
  firstButtonText?: string,
  secondButtonText?: string,
  firstButtonDestructive?: boolean,
  secondButtonDestructive?: boolean,
}

export function ModalCustomDialog({
  show,
  onHide,
  title,
  message,
  typeIcon,
  firstButtonText,
  secondButtonText,
  children,
  firstButtonDestructive,
  secondButtonDestructive,
}: Props) {

  const modalIcon = (typeIcon?: ModalIconOption) : any => {
    switch (typeIcon) {
      case 'success' : return (<Icon option='circle-shadow' icon='check-circle' size='xs' color='#039855' bgColor='#D1FADF' borderColor='#ECFDF3'/>);
      case 'warning' : return (<Icon option='circle-shadow' icon='alert-triangle' size='xs' color='#DC6803' bgColor='#FEF0C7' borderColor='#FFFAEB'/>);
      case 'error': return (<Icon option='circle-shadow' icon='alert-circle' size='xs' color='#D92D20' bgColor='#FEE4E2'borderColor='#FEF3F2'/>);
      case 'info': return (<Icon option='circle-shadow' icon='zap' size='xs' color='#367DFF' bgColor='#DFEBFF' borderColor='#EEF4FF'/>);
      case 'unlock': return (<Icon option='circle-shadow' icon='unlock' size='xs' color='#367DFF' bgColor='#DFEBFF' borderColor='#EEF4FF'/>);
      case 'exit': return (<Icon option='circle-shadow' icon='log-out' size='xs' color='#367DFF' bgColor='#DFEBFF' borderColor='#EEF4FF'/>);
      case 'enter': return (<Icon option='circle-shadow' icon='log-in' size='xs' color='#367DFF' bgColor='#DFEBFF' borderColor='#EEF4FF'/>);
      case 'delete': return (<Icon option='circle-shadow' icon='trash-2' size='xs' color='#367DFF' bgColor='#DFEBFF' borderColor='#EEF4FF'/>);
      default: return '';
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onHide(false);
        modalCustomDialogsStore.closeOnEscape();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onHide]);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      onHide(false);
      modalCustomDialogsStore.closeOnBackdropClick();
    }
  };

  if (!show) {
    return null;
  }

  return (
  <div className={styles['modal_backdrop']}
   onClick={handleClickOutside}>
    <div className={styles['modal_container']} >
      <div className={styles['modal_content']} aria-labelledby='modal-title' role='dialog' aria-modal='true'>
        <div className={styles['modal_header']}>
          <button type='button'
          onClick={() => {
            onHide(false);
            modalCustomDialogsStore.hideDialog();
          }} className={styles['modal_close_button']}>
          <Icon size='xs' icon='x' />
          </button>
          {typeIcon && modalIcon(typeIcon)}
          {!!title && <h5 className={styles['modal_title']}>{title}</h5>}
          {!!message && <p className={styles['modal_supporting-text']}>{message}</p>}
        </div>
        <div className={styles['modal_body']}>
          {children ?? ''}
        </div>
        <div className={styles['modal_footer']}>
          {!!firstButtonText && <Button
              hierarchy={`${modalCustomDialogsStore.firstButtonColor}`}
              onClick={() => onHide(false)}
              destructive={firstButtonDestructive}
            >
              {modalCustomDialogsStore.firstButtonText}
            </Button>}
          {!!secondButtonText && <Button
              hierarchy={`${modalCustomDialogsStore.secondButtonColor}`}
              onClick={() => onHide(true)}
              destructive={secondButtonDestructive}
            >
              {modalCustomDialogsStore.secondButtonText}
            </Button>}
        </div>
      </div>
    </div>
  </div>);
}