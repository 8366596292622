import { Emoji } from "@ui-kit/components/emoji/emoji";
import { ReactionTypes } from "src/assets/reactions";

const unicodeReactionMap: { [key: string]: ReactionTypes } = {
  "\u2764": "heart",
  "\u{1F44D}": "like",
  "\u{1F44E}": "dislike",
  "\u{1F602}": "joy",
  "\u{1F622}": "sad",
  "\u{1F621}": "angry",
};


export const renderTextEmoji = (text: string) => {
  const parts = text.split(/(\u2764|\u{1F44D}|\u{1F44E}|\u{1F602}|\u{1F622}|\u{1F621})/gu);

  return parts.map((part, index) => {
    const reactionType = unicodeReactionMap[part];
    if (reactionType) {
      return <Emoji key={index} type={reactionType} />;
    }
    return <span key={index}>{part}</span>;
  });
};
