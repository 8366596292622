import { observer } from "mobx-react-lite";
import { toastsStore, ToastType } from "@store";
import { Icon } from "@ui-kit";
import styles from "./error-fallback.module.css";

export const ToastList = observer(() => {
  const getColor = (toastType: ToastType) => {
    switch (toastType) {
      case ToastType.Success: return styles['toast-Success'];
      case ToastType.Error: return styles['toast-Error'];
      case ToastType.Warning: return styles['toast-Warning'];
      case ToastType.Copy: return styles['toast-Copy'];
      case ToastType.NotifyOn: return styles['toast-notify'];
      case ToastType.NotifyOff: return styles['toast-notify'];
      default: return styles['toast-Info'];
    }
  }

  const toastIcon = (toastType: ToastType) : any => {
    switch (toastType) {
      case ToastType.Error: return (<Icon option='circle' icon='alert-circle' size='xxs-plus' color='#D92D20' bgColor='#FEE4E2'/>);
      case ToastType.Success: return (<Icon option='circle' icon='check-circle' size='xxs-plus' color='#039855' bgColor='#D1FADF'/>);
      case ToastType.Warning: return (<Icon option='circle' icon='alert-triangle' size='xxs-plus' color='#DC6803' bgColor='#FEF0C7'/>);
      case ToastType.Copy: return (<Icon option='circle' icon='copy' size='xs-plus' color='#367DFF' bgColor='#DFEBFF'/>);
      case ToastType.NotifyOn: return (<Icon option='circle' icon='bell' size='xs' color='#367DFF' bgColor='#DFEBFF'/>);
      case ToastType.NotifyOff: return (<Icon option='circle' icon='bell-off' size='xs' color='#367DFF' bgColor='#DFEBFF'/>);
      default: return (<Icon option='circle' icon='zap' size='xs-plus' color='#367DFF' bgColor='#DFEBFF'/>);
    }
  }

  return (
    <div className={styles.toastContainer}>
      {toastsStore.toastList.map((toast) => (
        <div key={toast.id} className={`${styles.toast} ${getColor(toast.toastType)}`}>
          <div className={styles['toast-content']}>
          {toastIcon(toast.toastType)}
          <div className={styles['toast-message']}>{toast.message}</div>
            <button onClick={() => toastsStore.hideToast(toast.id)} className={styles['button_toast-close']}>
              <Icon size='xxs-plus' icon='x' />
            </button>
          </div>
          {!!toast.action && (
            <div className={styles['toast-action']}>
              <button onClick={() => {
                if (toast.action) {
                  toast.action();
                }
                toastsStore.hideToast(toast.id);
              }} >Read</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
});