import { Report } from "@graphql/graphql";
import { makeAutoObservable } from "mobx";

class ReportStore {
  report: Report|null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setReport(report: Report) {
    this.report = report;
  }
}

export const reportStore = new ReportStore();
