import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@shared/components";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo-client";
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CookiesProvider } from 'react-cookie';

import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
        <Suspense fallback='...loading'>
          <App />
        </Suspense>
        </Router>
      </ErrorBoundary>
    </ApolloProvider>
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
