import React from 'react';
import { Icon, Button } from "@ui-kit";
import { useTranslation } from "react-i18next";
import styles from './modal.module.css';

interface Props {
  show: boolean;
  onHide: () => void;
  title: string;
  message: string;
}

export function ModalMessage({ show, onHide, title, message }: Props) {
  const { t } = useTranslation('common');

  if (!show) {
    return null;
  }

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      onHide();
    }
  };

  return (
    <div className={styles['modal_backdrop']} onClick={handleClickOutside}>
      <div className={styles['modal_container']} role="dialog" aria-modal="true" aria-labelledby="modal-title">
        <div className={styles['modal_content']}>
          <div className={styles['modal_header']}>
            <h4 id="modal-title" className={styles['modal_title']}>{title}</h4>
            <button onClick={onHide} className={styles['modal_close_button']}>
            <Icon size='xs' icon='x' /></button>
          </div>
          <div className={styles['modal_body']}>
            <p className={styles['modal_supporting-text']}>{message}</p>
          </div>
          <div className={styles['modal_footer']}>
            <Button hierarchy='primary' onClick={onHide}>
              {t('modal.button.ok')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}