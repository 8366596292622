import React from "react";
import styles from "./reactions-text.module.css";
import { observer } from "mobx-react-lite";
import { ReactionComponent } from "../reaction/reaction.comp";
import { ReactionTypes } from "src/assets/reactions";

type Props = {
  showReactions: boolean,
  setShowReactions?: (prev: boolean) => void,
  onEmojiSelect: (emoji: ReactionTypes) => void,
  isMessage?: boolean,
};

export const types = ["heart", "like", "dislike", "joy", "sad", "angry"];

export const ReactionsTextComponent = observer(({ showReactions, setShowReactions, onEmojiSelect, isMessage }: Props) => {

  const handleClick = async (type: ReactionTypes) => {
    onEmojiSelect(type);
    if(setShowReactions) setShowReactions(false);
  }

  return (
    <div className={`${styles['reactions-container']} ${isMessage ? styles['messages'] : styles['new-posts']}`}>
      {showReactions && (
        <div className={styles.reactions}>
          {types.map((type) => (
            <ReactionComponent
              key={type}
              type={type as ReactionTypes}
              onClick={() => handleClick(type as ReactionTypes)}
            />
          ))}
        </div>
      )}
    </div>
  );
});
