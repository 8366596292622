import { gql } from "@apollo/client";

export const follow = gql`mutation follow($username: String) {
    follow(username: $username){
        id
        username
        follow
        createdAt
        updatedAt
    }
}`;
