import { gql } from "@apollo/client";

export const getPostComments = gql`query getPostComments($input: GetPostCommentsInput) {
    getPostComments(input: $input){
        items{
            id
            postId
            commentId
            answer
            username
            text
            media{
                url
                key
                type
            }
            userPicture{
                username
                firstname
                lastname
                gender
                dateOfBirth
                picture{
                    url
                    key
                    type
                }
                cityId
                city{
                    id
                    place_id
                    name
                    username
                    picture{
                        url
                        key
                        type
                    }
                    longName
                    country
                    langs{
                        lang
                        name
                        country
                    }
                    utc_offset
                    createdAt
                    updatedAt
                }
                following
                followers
                roles{
                    id
                    name
                    title
                    type
                    color
                    textColor
                    isVisible
                }
                postsLength
                isPremiun
                blockedYou
                isBlocked
                isFollow
                isOnline
                onlineSince
                isFollowing
                email
                banned
                warnings{
                    id
                    post{
                        id
                        text
                        username
                        city{
                            id
                            place_id
                            name
                            username
                            picture{
                                url
                                key
                                type
                            }
                            longName
                            country
                            langs{
                                lang
                                name
                                country
                            }
                            utc_offset
                            createdAt
                            updatedAt
                        }
                        reactions{
                            id
                            postId
                            type
                            username
                            isHiden
                            createdAt
                            updatedAt
                        }
                        commentsLength
                        media{
                            url
                            key
                            type
                        }
                        lat
                        lng
                        view
                        isSaved
                        createdAt
                        updatedAt
                        token
                        isHiden
                        follow
                        following
                        linkData{
                            id
                            url
                            title
                            text
                            image{
                                url
                                height
                                width
                                type
                            }
                            video{
                                url
                                height
                                width
                                type
                            }
                        }
                        answer
                    }
                    chat{
                        chatId
                        username
                        usernames
                        admins
                        moders
                        title
                        isChat
                        isPublic
                        media{
                            url
                            key
                            type
                        }
                        typing
                        offNotifyUsernames
                        lastMessage{
                            id
                            chatId
                            username
                            usernames
                            read
                            delivered
                            text
                            media{
                                url
                                key
                                type
                            }
                            reactions{
                                id
                                postId
                                type
                                username
                                isHiden
                                createdAt
                                updatedAt
                            }
                            createdAt
                            updatedAt
                        }
                        newMessagesCount
                        createdAt
                        updatedAt
                    }
                    message{
                        id
                        chatId
                        username
                        usernames
                        read
                        delivered
                        text
                        media{
                            url
                            key
                            type
                        }
                        reactions{
                            id
                            postId
                            type
                            username
                            isHiden
                            createdAt
                            updatedAt
                        }
                        createdAt
                        updatedAt
                    }
                    username
                    reporterUsername
                    reasonType
                    reason
                    isHandled
                    isApprooved
                    decisionText
                    decisionType
                    createdAt
                    updatedAt
                }
            }
            reactions{
                id
                postId
                type
                username
                isHiden
                createdAt
                updatedAt
            }
            commentsLength
            follow
            following
            isHiden
            createdAt
            updatedAt
        }
        nextToken
    }
}`;
